@import "~@blueprintjs/core/lib/scss/variables";

.ss-dashboard {
  .ss-dashboard-slider {
    padding-left: 10px;
    padding-right: 10px;
  }

  &-chart {
    h3 {
      margin-left: 30px;
      margin-right: 30px;
    }
  }
}

.bp3-datepicker .DayPicker-Day--hasShows {
  position: relative;
  &::after {
    background: rgba($green1, 0.3);
    position: absolute;
    display: block;
    top: 2px;
    left: 2px;
    right: 1px;
    bottom: 2px;
    content: " ";
    border-radius: 2px;
  }
}
