.ss-padded-box {
  display: flex;
  flex-wrap: wrap;
  margin: -5px;

  &-vertical {
    flex-direction: column;
  }

  > * {
    padding: 5px;
  }
}
