@import "node_modules/@blueprintjs/core/lib/scss/variables";

.ss-actionbar {
  height: 46px;
  background: $dark-gray2;
  display: flex;
  align-items: center;
  padding: $pt-grid-size;

  box-shadow: 0 0 2px 1px $dark-gray1;

  &-body {
    padding: $pt-grid-size;
    flex-grow: 1;
  }

  .bp4-divider {
    height: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .ss-actionbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background: rgba($dark-gray2, 0.96);
  }
}
