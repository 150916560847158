.ss-login-page {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &-card {
    width: 400px;
  }

}

@media only screen and (max-width: 600px) {
  #root {
    .ss-login-page {
      padding-left: 10px;
      padding-right: 10px;
      justify-content: flex-start;
      padding-top: 10px;

      &-card {
        width: 100%;

        h2 {
          font-size: 120%;
        }
      }
    }
  }
}
