@import "~@blueprintjs/core/lib/scss/variables";

.ss-page {
  padding: $pt-grid-size * 2;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  > *:not(:first-child) {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .ss-page {
    &-container {
      padding-top: 56px;
    }
    padding: $pt-grid-size;
  }
}
