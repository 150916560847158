@import-normalize;

@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/select/lib/css/blueprint-select.css";
@import "~@blueprintjs/datetime/lib/css/blueprint-datetime.css";
@import "~@blueprintjs/core/lib/scss/variables";

html,
body,
#root {
  margin: 0;
  height: 100%;

  background: $dark-gray2;
}
